import React, { useState } from 'react';
import { Sun, Moon, Copy } from 'lucide-react';

const App = () => {
  const [darkMode, setDarkMode] = useState(true);

  const copyAddress = () => {
    navigator.clipboard.writeText('9wRK4zEoHFM8S44stF6tY8a8mNj36s5LLzYXkT6zpump');
    alert('Contract address copied to clipboard!');
  };

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
      <div className="container mx-auto px-4 py-8">
        <header className="flex justify-between items-center mb-6">
          <h1 className="text-4xl font-bold">🎧 on SOL</h1>
          <button
            onClick={() => setDarkMode(!darkMode)}
            className={`p-3 rounded-full ${darkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-gray-200 hover:bg-gray-300'} transition-colors duration-200`}
          >
            {darkMode ? <Sun size={24} /> : <Moon size={24} />}
          </button>
        </header>

        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Contract Address:</h2>
          <div className="bg-opacity-20 bg-white p-4 rounded-lg shadow-lg flex items-center justify-between">
            <p className="text-sm md:text-base font-mono break-all">9wRK4zEoHFM8S44stF6tY8a8mNj36s5LLzYXkT6zpump</p>
            <button onClick={copyAddress} className="ml-4 p-2 rounded-full bg-gray-700 hover:bg-gray-600 transition-colors duration-200">
              <Copy size={20} />
            </button>
          </div>
          <p className="mt-2 text-sm md:text-base">Network: <span className="font-semibold">Solana</span></p>
        </section>
        
        <main>
          <section className="mb-16 text-center">
            <img src="/headphones.jpg" alt="🎧 on SOL" className="mx-auto mb-8 rounded-lg shadow-xl" style={{maxWidth: '150px'}} />
            <h2 className="text-3xl font-semibold mb-6">The headphones stay on</h2>
            <p className="mb-8 text-xl">Mfers do what we want</p>
            <a 
              href="https://raydium.io/swap/?inputMint=So11111111111111111111111111111111111111112&outputMint=9wRK4zEoHFM8S44stF6tY8a8mNj36s5LLzYXkT6zpump"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-full text-lg transition-colors duration-200 shadow-lg inline-block"
            >
              Buy 🎧 on SOL
            </a>
          </section>

          <section className="mb-16">
            <h2 className="text-3xl font-semibold mb-6">Found on:</h2>
            <div className="flex justify-center items-center space-x-8">
              <a href="https://pump.fun"><img src="/pumpfun.png" alt="Pump.fun" className="h-12 object-contain" /></a>
              <a href="https://dexscreener.com"><img src="/dexscreener.png" alt="DexScreener" className="h-12 object-contain" /></a>
              <a href="https://solana.com"><img src="/solana.png" alt="Solana" className="h-12 object-contain" /></a>
            </div>
          </section>

          <section className="grid md:grid-cols-3 gap-8 mb-16">
            <div className="bg-opacity-20 bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold mb-4">Solana-Powered</h3>
              <p>Leveraging Solana's high-speed, low-cost blockchain for efficient transactions and smart contracts.</p>
            </div>
            <div className="bg-opacity-20 bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold mb-4">Decentralized Trading</h3>
              <p>Easily trade 🎧 coin on decentralized exchanges like Raydium, ensuring liquidity and accessibility.</p>
            </div>
            <div className="bg-opacity-20 bg-white p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold mb-4">Community Governed</h3>
              <p>🎧 coin holders can participate in governance decisions, shaping the future of the project.</p>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default App;